import React from 'react';
import { ModalPortal, Button } from '../../../components';
import css from './PackagesBenefitsModal.css';
import { IconCheckCircleL } from '../../../icons';

const content = {
    socialBoost: {
        title: <h3 className={css.title}>Erhalte mehr Sichtbarkeit über unsere Socials</h3>,
        description: (
            <p className={css.description}>Das Zusatzpaket beinhaltet folgende Leistungen:</p>
        ),
        benefits: [
            <p>Wir teilen und bewerben dein Inserat aktiv auf Facebook mit 11’500+ Followern.</p>,
            <p>Wir teilen und bewerben dein Inserat aktiv auf Instagram mit 13’300+ Followern.</p>,
            <p>
                Wir teilen dein Inserat in unserer exklusiven{' '}
                <a
                    href="https://www.facebook.com/groups/436632103052370"
                    rel="nofollow"
                    target="_blank"
                >
                    Facebook-Gruppe
                </a>{' '}
                mit 12’000+ Mitgliedern.
            </p>,
        ],
    },
    anonymousListing: {
        title: <h3 className={css.title}>Inseriere dein Pferd anonym und schütze deine Daten</h3>,
        description: (
            <p className={css.description}>Das Zusatzpaket beinhaltet folgende Leistungen:</p>
        ),
        benefits: [
            <p>
                Der Name deines Pferdes wird ab sofort und für die gesamte Laufzeit des Inserats
                anonymisiert.
            </p>,
            <p>
                Dein öffentliches Profil wird ab sofort verborgen, bis Du eine Anfrage akzeptierst.
            </p>,
            <p>Dein Name wird ab sofort anonymisiert, bis Du eine Anfrage akzeptierst.</p>,
        ],
    },
};

export default ({ onClose, isOpen, category }) => (
    <ModalPortal
        id="PackagesBenefitsModal"
        isOpen={isOpen}
        onClose={onClose}
        contentClassName={css.modalContent}
    >
        {content[category].title}
        {content[category].description}
        {
            <div className={css.benefitsHolder}>
                {content[category].benefits.map((b, i) => (
                    <div key={i} className={css.benefitItem}>
                        <IconCheckCircleL /> {b}
                    </div>
                ))}
            </div>
        }
        <Button type="button" onClick={onClose}>
            Schliessen
        </Button>
    </ModalPortal>
);

import React, { useEffect, useState } from 'react';
import { Slide } from 'pure-react-carousel';
import { NamedLink, ModalPortal, ImageCarousel, SdkVideo } from '../../../../components';
import Carousel from '../../../../components/ListingCardsCarousel/Carousel';
import css from './SectionTestimonials.css';

const variant = 'landscape-crop2x';

const getPosterPath = (userName, format = '9x16') =>
    `https://horsedeal.imgix.net/static/testimonials/testimonial-${userName}-${format}-thumbnail.webp`;

const testimonials = [
    {
        thumbnail: getPosterPath('florence-hofmann'),
        videos: {
            '9x16': '1069329376?h=40c70d15d4',
            '16x9': '1069328942?h=f55478879e',
        },
    },
    {
        thumbnail: getPosterPath('pauline-roeder'),
        videos: {
            '9x16': '1069274672?h=6196a11d29',
            '16x9': '1069274480?h=ee9fe72b67',
        },
    },
    {
        thumbnail: getPosterPath('anja-k'),
        videos: {
            '9x16': '1069271066?h=3b00f16141',
            '16x9': '1069270819?h=92b23403cc',
        },
    },
    {
        thumbnail: getPosterPath('claudia-makabu'),
        videos: {
            '9x16': '1069271539?h=90067abb49',
            '16x9': '1069271266?h=1c45866d7e',
        },
    },
    {
        thumbnail: getPosterPath('alessia-koenig'),
        videos: {
            '9x16': '1069271980?h=32b31dd626',
            '16x9': '1069271751?h=2683893cca',
        },
    },
    {
        thumbnail: getPosterPath('ruby-walliser'),
        videos: {
            '9x16': '1069274220?h=1c2f1c43df',
            '16x9': '1069273990?h=f5b06643f9',
        },
    },
    {
        thumbnail: getPosterPath('patrizia-rothe'),
        videos: {
            '9x16': '1069272365?h=2ce1b85472',
            '16x9': '1069272165?h=f526338446',
        },
    },
    {
        thumbnail: getPosterPath('joelle-wenczel'),
        videos: {
            '9x16': '1069273089?h=8666c658ef',
            '16x9': '1069272548?h=e1db120713',
        },
    },
];

const getTestimonialsEntities = f => t => ({
    type: 'embedded',
    src: t.videos[f],
});

export default ({ isMobile }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://player.vimeo.com/api/player.js';
        script.defer = false;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
            <section className={css.root}>
                <header className={css.header}>
                    <h3 className={css.heading}>Erfolgsgeschichten</h3>
                </header>
                <div className={css.testimonialsHolder}>
                    <Carousel
                        isMobile={isMobile}
                        items={testimonials}
                        touchEnabled={isMobile}
                        sliderVisibleSlides={isMobile ? 1 : 3}
                        sliderClassName={css.slider}
                        hideButtonsOnEndStart
                        naturalSlideWidth={9}
                        naturalSlideHeight={16}
                        showDots
                        rendererCarousel={(testimonial, i) => {
                            const { thumbnail, videos } = testimonial;
                            const format = '9x16';
                            const path = videos[format];
                            const list = ['video/mp4', 'video/ogg', 'video/webm'];

                            return (
                                <Slide
                                    index={i}
                                    key={i}
                                    className={css.videoHolder}
                                    onClick={e => {
                                        e.preventDefault();
                                        setSelectedImageIndex(i);
                                        setModalOpen(true);
                                    }}
                                >
                                    <video poster={thumbnail}>
                                        {list.map(type => (
                                            <source src={path} type={type} />
                                        ))}
                                        Your browser doesn't support HTML5 video tag.
                                    </video>
                                </Slide>
                            );
                        }}
                    />
                </div>
                <footer className={css.footer}>
                    <NamedLink name="SignupPage" className={css.subLinkPrimary}>
                        Kostenlos registrieren
                    </NamedLink>
                </footer>
            </section>

            {modalOpen && (
                <ModalPortal
                    id="ListingPage.imageCarousel"
                    scrollLayerClassName={css.carouselModalScrollLayer}
                    containerClassName={css.carouselModalContainer}
                    contentClassName={css.modalContent}
                    // closeButtonMessage={null}
                    lightCloseButton
                    isOpen
                    onClose={() => setModalOpen(false)}
                >
                    <ImageCarousel
                        images={testimonials.map(
                            getTestimonialsEntities(isMobile ? '9x16' : '16x9')
                        )}
                        selectedImageIndex={selectedImageIndex}
                        variants={[variant]}
                        renderEmbeddedElem={({ src }) => (
                            <iframe
                                src={`https://player.vimeo.com/video/${src}&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                                frameborder="0"
                                allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                title="testimonial-florence-hofmann-9x16"
                            />
                        )}
                    />
                </ModalPortal>
            )}
        </>
    );
};

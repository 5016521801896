import React, { useState } from 'react';
import { FieldCheckbox, Button, SecondaryButton } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';
import { IconBoost3xL, IconLockL } from '../../../icons';
import commonCss from '../EditListingPricingForm.css';
import css from './EditListingPricingPagePackagesList.css';
import PackagesBenefitsModal from './PackagesBenefitsModal';

const formatPrice = price => (Math.round(price) / 100).toFixed(2);

const iconsMapper = {
    socialBoost: <IconBoost3xL />,
    anonymousListing: <IconLockL />,
};

export default ({
    displayPackages,
    packagePurchased,
    navigateToCheckoutStep,
    loading,
    valuesSelected,
}) => {
    const [modalCategory, setModalCategory] = useState(null);

    return (
        <>
            {!!modalCategory && (
                <PackagesBenefitsModal
                    category={modalCategory}
                    isOpen
                    onClose={() => setModalCategory(null)}
                />
            )}

            <section className={css.packagesSection}>
                {displayPackages.map(
                    ({
                        category,
                        title,
                        price,
                        currency,
                        id,
                        description_short,
                        badges,
                        disabled,
                    }) => (
                        <React.Fragment key={id}>
                            {category && <p className={css.packagesCategoryHeading}>{category}</p>}
                            <div className={css.packagesWrapper}>
                                {!!iconsMapper[id] && (
                                    <div className={css.iconHolder}>{iconsMapper[id]}</div>
                                )}

                                <div className={css.packagesUpperSection}>
                                    <div>
                                        <h4 className={css.packagesTitle}>{title}</h4>

                                        <aside className={css.packagesPricingInfo}>
                                            <span className={css.packagesPrice}>
                                                {formatPrice(price)} {currency}
                                            </span>
                                            <span className={css.packagesSubscriptionNote}>
                                                ・Kein Abonnement
                                            </span>
                                        </aside>
                                    </div>
                                    <FieldCheckbox
                                        type="checkbox"
                                        id={id}
                                        name={id}
                                        className={commonCss.noPaddingCheckbox}
                                        togglerMode
                                        reversePosition
                                        disabled={disabled}
                                    />
                                </div>

                                <p className={css.packagesDescription}>
                                    {description_short} <br />
                                    <span
                                        className={css.moreInfoLink}
                                        onClick={() => setModalCategory(id)}
                                    >
                                        Was ist enthalten?
                                    </span>
                                </p>

                                <footer className={css.badges}>
                                    {(badges || []).concat('Verfügbar').map(b => (
                                        <div key={`badge-${b}`}>{b}</div>
                                    ))}
                                </footer>
                            </div>
                        </React.Fragment>
                    )
                )}
                <footer className={css.proceedWithFooter}>
                    {packagePurchased && (
                        <Button
                            type="button"
                            className={css.proceedWithPackageButton}
                            onClick={() => navigateToCheckoutStep()}
                            inProgress={loading}
                            disabled={loading || valuesSelected.length === 0}
                        >
                            <FormattedMessage id="EditListingWizard.saveEditandNew" />
                        </Button>
                    )}
                    <SecondaryButton type="submit" className={css.proceedWithNoPackageButton}>
                        <FormattedMessage id="EditListingPricingForm.proceedWithNoPackageButton" />
                    </SecondaryButton>
                </footer>
            </section>
        </>
    );
};

import { LOGIN_COMPLETE, LOGOUT_SUCCESS } from '../ducks/Auth.duck';
import {
    checkIOS,
    notifyIosAppOnLogInNoSubscription,
    notifyIosAppOnLogInSubscriptionEnabled,
} from '../util/ios';

const loginToOS = async (oneSignalInstance, currentUser) => {
    try {
        const { isEnabled } = await oneSignalInstance.isSubscriptionsEnabled();
        const { isNative } = checkIOS();

        if (!isEnabled && isNative) {
            /** isNative - send a message to both IOS & Android native apps */
            return notifyIosAppOnLogInNoSubscription();
        }
        if (isEnabled && currentUser && !isNative) {
            /** if a user already subscribed to OS, re-login him to OS system */
            return oneSignalInstance.login(oneSignalInstance.getExternalId(currentUser));
        }
        if (isEnabled && currentUser && isNative) {
            /** if a user already subscribed to OS, re-login him to OS system (Native app) */
            return notifyIosAppOnLogInSubscriptionEnabled(currentUser);
        }
    } catch (e) {
        // TODO
    }
};

export const oneSignalMiddleware = oneSignalInstance => ({ getState }) => next => async action => {
    const { type } = action;

    if (type === LOGIN_COMPLETE && oneSignalInstance) {
        /**
         * current user data is needed, so the login complete event
         * is used so that fetchCurrentUser thunk is ready
         */
        const { currentUser } = getState().user;
        loginToOS(oneSignalInstance, currentUser);
        // do not need to wait for OS response
        return next(action);
    }

    if (type === LOGOUT_SUCCESS) {
        /** log out from OneSignal */
        oneSignalInstance.logout();
    }

    next(action);
};

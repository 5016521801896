import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';
import classNames from 'classnames';

import { getCurrentUserLocation, updateCurrentUserLocationField } from '../../util/localStorage';
import { pathParamsToNextTab } from '../../components/EditListingWizard/EditListingWizardTab';
import config from '../../config';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { Page, LayoutWrapperMain, InterruptProcessModal, WizardNavigation } from '../../components';
import CollectUserInfoAgeForm from '../../components/CollectUserInfoWizard/CollectUserInfoAgeForm';
import CollectUserDisciplineForm from '../../components/CollectUserInfoWizard/CollectUserDisciplineForm';
import CollectUserInfoLocationForm from '../../components/CollectUserInfoWizard/CollectUserInfoLocationForm';
import CollectUserInterestForm from '../../components/CollectUserInfoWizard/CollectUserInterestForm';
import CollectUserAboutForm from '../../components/CollectUserInfoWizard/CollectUserAboutForm';
import CollectUserAvailabilityForm from '../../components/CollectUserInfoWizard/CollectUserAvailabilityForm';
import CollectRecommendationsForm from '../../components/CollectUserInfoWizard/CollectRecommendationsForm';
import { uploadImage, updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { IconCloseCircleL, IconLockL } from '../../icons';

import css from './OnboardingPage.css';
import { resolveCollectingUserInfo, discardErrors, promptUserBio } from './OnboardingPage.duck';
import CollectUserLevelForm from '../../components/CollectUserInfoWizard/CollectUserLevelForm';
import { RIDER_AVAILABILITY_NOT_AVAILABLE } from '../../marketplace-custom-config';
import { sendRecommendations, updateCreditsHistory } from '../../util/api';
import { CREDITS_ACTION_INITIAL_PURCHASE } from '../../credits-config';
import mixpanel from 'mixpanel-browser';
import { capitalize } from 'lodash';
import { extractIdentityProvider, userTypeForTracking } from '../../mixpanel/helper';

export const ONBOARDING_INTERRUPTED = 'ONBOARDING_INTERRUPTED';
export const ONBOARDING_INVITES_SENT = 'ONBOARDING_INVITES_SENT';

export const isMobile = () => typeof window !== 'undefined' && window.innerWidth < MODAL_BREAKPOINT;

const COLLECT_USER_INFO_HORSEOWNER_STEP_1 = 'birthdate';
const COLLECT_USER_INFO_HORSEOWNER_STEP_2 = 'location';
const COLLECT_USER_INFO_HORSEOWNER_STEP_3 = 'about';

const COLLECT_USER_INFO_RIDER_STEP_1 = 'birthdate';
const COLLECT_USER_INFO_RIDER_STEP_2 = 'location';
const COLLECT_USER_INFO_RIDER_STEP_3 = 'interest';
const COLLECT_USER_INFO_RIDER_STEP_4 = 'availability';
const COLLECT_USER_INFO_RIDER_STEP_5 = 'discipline';
const COLLECT_USER_INFO_RIDER_STEP_6 = 'level';
const COLLECT_USER_INFO_RIDER_STEP_7 = 'about';
const COLLECT_USER_INFO_RIDER_STEP_8 = 'recommendation';

const MODAL_BREAKPOINT = 768;

const { userTypeRider, defaultCountry, maps } = config;
const { supportedCountries } = maps;

const riderAccountTabs = [COLLECT_USER_INFO_RIDER_STEP_1, COLLECT_USER_INFO_RIDER_STEP_2];
const riderProfileTabs = [
    COLLECT_USER_INFO_RIDER_STEP_3,
    COLLECT_USER_INFO_RIDER_STEP_4,
    COLLECT_USER_INFO_RIDER_STEP_5,
    COLLECT_USER_INFO_RIDER_STEP_6,
    COLLECT_USER_INFO_RIDER_STEP_7,
    COLLECT_USER_INFO_RIDER_STEP_8,
];
const riderTabs = [...riderAccountTabs, ...riderProfileTabs];

const horseownerAccountTabs = [
    COLLECT_USER_INFO_HORSEOWNER_STEP_1,
    COLLECT_USER_INFO_HORSEOWNER_STEP_2,
];
const horseownerProfileTabs = [COLLECT_USER_INFO_HORSEOWNER_STEP_3];
const horseownerTabs = [...horseownerAccountTabs, ...horseownerProfileTabs];

const OnboardingPageComponent = ({
    intl,
    updateInfoInProgress,
    updateInfoError,
    history,
    params: { tab: currentStep },
    currentUser: user,
    onUpdateUserInfo,
    onImageUpload,
    uploadImageError,
    uploadInProgress,
    profileImage,
    onUpdateProfile,
    userDocErrorMetadata,
    uploadDocumentsError,
    onDiscardErrors,
    onPromptUserBio,
    promptUserBioInProgress,
    promptUserBioError,
    promptUserBio,
}) => {
    const [interruptModalVisible, setInterruptModalVisibility] = useState(false);
    const loading = updateInfoInProgress;
    const preventRedirect = loading || !user;

    const title = intl.formatMessage({
        id: 'OnboardingPage.title',
    });
    const currentUser = ensureCurrentUser(user);
    const { attributes, profileImage: profileImageFromUserData } = currentUser;
    const { profile } = attributes;
    const { bio: bioFromUserData, publicData } = profile;
    const {
        userType,
        /** AGE */
        birthDate,
        /** LOCATION */
        country: publicDataCountry,
        streetAddress,
        houseNumber,
        postalCode,
        city,
        /** INTERESTS */
        interest,
        /** AVAILABILITY */
        availability,
        openForPartTimeProposals,
        /** DISCIPLINES */
        desiredDisciplines,
        /** LEVEL */
        disciplinesLevel,
        userLocation,
    } = publicData;

    const isRider = userType === userTypeRider;
    const isRiderProfile = isRider && riderProfileTabs.includes(currentStep); // interruptionAllowed
    const isHorseownerProfile = !isRider && horseownerProfileTabs.includes(currentStep); // interruptionAllowed

    const riderSectionTabs = isRiderProfile ? riderProfileTabs : riderAccountTabs;
    const horseownerSectionTabs = isHorseownerProfile
        ? horseownerProfileTabs
        : horseownerAccountTabs;
    const sectionTabs = isRider ? riderSectionTabs : horseownerSectionTabs;
    const supportedTabs = isRider ? riderTabs : horseownerTabs;

    const tabStep = 100 / sectionTabs.length;
    const stepIndex = sectionTabs.indexOf(currentStep) + 1;
    const progressBarWidth = stepIndex * tabStep;

    const isFirstPage =
        currentStep === COLLECT_USER_INFO_HORSEOWNER_STEP_1 ||
        currentStep === COLLECT_USER_INFO_RIDER_STEP_1;

    const interruptAllowed = isRiderProfile || isHorseownerProfile;

    const interruptOnboarding = () => {
        if (!interruptAllowed) return;

        setInterruptModalVisibility(true);
    };

    const tabCompleted = tab => {
        switch (tab) {
            case COLLECT_USER_INFO_RIDER_STEP_1:
                return !!birthDate;
            case COLLECT_USER_INFO_RIDER_STEP_2:
                return !!(publicDataCountry && streetAddress && houseNumber && postalCode && city);
            case COLLECT_USER_INFO_RIDER_STEP_3:
                return !!interest;
            case COLLECT_USER_INFO_RIDER_STEP_4:
                return !!availability;
            case COLLECT_USER_INFO_RIDER_STEP_5:
                return !!(desiredDisciplines && desiredDisciplines.length);
            case COLLECT_USER_INFO_RIDER_STEP_6:
                return !!(disciplinesLevel && desiredDisciplines.every(d => disciplinesLevel[d]));
            case COLLECT_USER_INFO_RIDER_STEP_7:
                return !!profileImageFromUserData;
            case COLLECT_USER_INFO_HORSEOWNER_STEP_1:
                return !!birthDate;
            case COLLECT_USER_INFO_HORSEOWNER_STEP_2:
                return !!(publicDataCountry && streetAddress && houseNumber && postalCode && city);
            case COLLECT_USER_INFO_HORSEOWNER_STEP_3:
                return !!profileImageFromUserData;
            default:
                return false;
        }
    };

    const tabsActive = profile =>
        supportedTabs.reduce((acc, tab) => {
            const previousTabIndex = supportedTabs.findIndex(t => t === tab) - 1;
            const isActive =
                previousTabIndex >= 0
                    ? tabCompleted(supportedTabs[previousTabIndex], profile)
                    : true;
            return { ...acc, [tab]: isActive };
        }, {});

    const navigateToPreviousForm = () => {
        if (isFirstPage) return;
        // Redirect to a previous tab
        history.goBack();
    };

    const onCompleteCollectUserInfoWizardTab = async ({
        publicData,
        profileData,
        isLastStep,
        interrupt = false,
        skipRedirect = false,
    }) => {
        if (isLastStep) {
            const { profile } = currentUser.attributes;

            const tabsStatus = tabsActive(profile);

            const tabMissed = Object.keys(tabsStatus).reduce((notFilled, tab) => {
                if (notFilled) return notFilled;
                if (!tabsStatus[tab]) {
                    notFilled = tab;
                }
                return notFilled;
            }, null);

            if (tabMissed && !interrupt) {
                await onUpdateUserInfo(publicData, profileData);
                return history.push(tabMissed);
            }
        }
        const isRider = userType === userTypeRider;

        await onUpdateUserInfo(
            interrupt
                ? {
                      availabilityStatus: isRider ? RIDER_AVAILABILITY_NOT_AVAILABLE : null,
                      ...publicData,
                  }
                : publicData,
            profileData,
            isLastStep || interrupt
        );
        mixpanel.track(
            `${userTypeForTracking(userType)} Sign Up ${capitalize(currentStep)} Step Completed`,
            { skipped: !!interrupt }
        );

        if (isLastStep || interrupt) {
            // sadly we have to artificially postpone this tracking to ensure the right order with
            // the ...Step Completed event above
            setInterval(
                mixpanel.track(`${userTypeForTracking(userType)} Sign Up Completed`, {
                    auth_method: extractIdentityProvider(currentUser),
                }),
                1000
            );
        }

        if (!isLastStep && !skipRedirect && !interrupt) {
            // Redirect to a next tab
            const { tab } = pathParamsToNextTab({}, currentStep, supportedTabs);
            history.push(tab);
        }
    };

    const getCurrentStepForm = () => {
        const protectionMsg = intl.formatMessage({
            id: 'CollectUserInfoWizard.infoUnderProtection',
        });
        const protectionMsgAdresse = intl.formatMessage({
            id: 'CollectUserInfoWizard.infoUnderProtectionAdresse',
        });
        const protectionMsgRecommendations = intl.formatMessage({
            id: 'CollectUserInfoWizard.infoProtectedRecommendations',
        });
        const msgSection = msg => (
            <div className={css.msgSection}>
                <IconLockL />
                {msg}
            </div>
        );

        /** AGE */
        const ageForm = (
            <CollectUserInfoAgeForm
                updateInfoInProgress={updateInfoInProgress}
                initialValues={
                    birthDate
                        ? { birthDate }
                        : { birthDate: { day: null, month: null, year: null } }
                }
                userName={profile.firstName}
                onSubmit={values => {
                    onCompleteCollectUserInfoWizardTab({ publicData: values });
                }}
            >
                {msgSection(protectionMsg)}
            </CollectUserInfoAgeForm>
        );
        /** LOCATION */
        const {
            country: ipAddressCountry = defaultCountry,
            countrySelected,
        } = getCurrentUserLocation();

        const countryCodeResolved =
            publicDataCountry || supportedCountries[countrySelected]
                ? countrySelected
                : supportedCountries[ipAddressCountry]
                ? ipAddressCountry
                : defaultCountry;

        const locationForm = (
            <CollectUserInfoLocationForm
                navigateToPreviousForm={navigateToPreviousForm}
                updateInfoInProgress={updateInfoInProgress}
                userType={userType}
                initialValues={{
                    streetAddress: (streetAddress || '').trim(),
                    city: (city || '').trim(),
                    houseNumber,
                    postalCode,
                    country: countryCodeResolved,
                    userLocation,
                }}
                onSubmit={({ location, ...rest }) => {
                    const userLocation = location || rest.userLocation;

                    if (!userLocation) return;

                    onCompleteCollectUserInfoWizardTab({
                        publicData: {
                            ...rest,
                            userLocation,
                        },
                    });
                    /**
                     * refresh ls country value
                     */
                    updateCurrentUserLocationField(
                        'countrySelected',
                        rest.country || defaultCountry
                    );
                    /**
                     * the last mandatory step is over;
                     * update a user's bonus credits data
                     */
                    updateCreditsHistory(
                        JSON.stringify({
                            userId: currentUser.id.uuid,
                            eventName: CREDITS_ACTION_INITIAL_PURCHASE,
                        })
                    );
                }}
            >
                {msgSection(protectionMsgAdresse)}
            </CollectUserInfoLocationForm>
        );
        /** INTEREST */
        const interestForm = (
            <CollectUserInterestForm
                navigateToPreviousForm={navigateToPreviousForm}
                updateInfoInProgress={updateInfoInProgress}
                initialValues={{ interest }}
                onSubmit={values => onCompleteCollectUserInfoWizardTab({ publicData: values })}
            />
        );
        /** AVAILABILITY */
        const availabilityForm = (
            <CollectUserAvailabilityForm
                navigateToPreviousForm={navigateToPreviousForm}
                updateInfoInProgress={updateInfoInProgress}
                initialValues={{ availability, openForPartTimeProposals }}
                onSubmit={values =>
                    onCompleteCollectUserInfoWizardTab({
                        publicData: {
                            ...values,
                            availabilityStatus: RIDER_AVAILABILITY_NOT_AVAILABLE,
                        },
                    })
                }
            />
        );
        /** DISCIPLINE */
        const disciplineForm = (
            <CollectUserDisciplineForm
                navigateToPreviousForm={navigateToPreviousForm}
                updateInfoInProgress={updateInfoInProgress}
                initialValues={{ disciplines: desiredDisciplines }}
                onSubmit={({ desiredDisciplines }) =>
                    onCompleteCollectUserInfoWizardTab({
                        publicData: {
                            desiredDisciplines,
                        },
                    })
                }
            />
        );

        /** LEVEL */
        const levelForm = (
            <CollectUserLevelForm
                currentUser={currentUser}
                navigateToPreviousForm={navigateToPreviousForm}
                updateInfoInProgress={updateInfoInProgress}
                initialValues={{ disciplines: desiredDisciplines, disciplinesLevel }}
                onSubmit={({ disciplinesLevel }) =>
                    onCompleteCollectUserInfoWizardTab({
                        publicData: {
                            disciplinesLevel,
                        },
                    })
                }
            />
        );
        /** ABOUT */
        const aboutForm = (
            <CollectUserAboutForm
                bio={bioFromUserData}
                promptUserBio={promptUserBio}
                currentUser={currentUser}
                updateInfoInProgress={updateInfoInProgress}
                navigateToPreviousForm={navigateToPreviousForm}
                initialValues={{ bio: promptUserBio || bioFromUserData }}
                onSubmit={values => {
                    if (!values.bio) {
                        values.bio = null;
                    }
                    const isLastStep = !isRider;
                    onCompleteCollectUserInfoWizardTab({
                        publicData: {},
                        profileData: { bio: values.bio },
                        isLastStep,
                    });
                }}
                onImageUpload={onImageUpload}
                uploadImageError={uploadImageError}
                uploadInProgress={uploadInProgress}
                profileImage={profileImage}
                onUpdateProfile={onUpdateProfile}
                onPromptUserBio={onPromptUserBio}
                promptUserBioInProgress={promptUserBioInProgress}
            />
        );
        /** RECOMMENDATIONS */
        const recommendationsForm = (
            <CollectRecommendationsForm
                currentUser={currentUser}
                navigateToPreviousForm={navigateToPreviousForm}
                updateInfoInProgress={updateInfoInProgress}
                onSubmit={({ recipientsList, message }) => {
                    if (recipientsList && recipientsList.length && message) {
                        sendRecommendations(JSON.stringify({ recipientsList, message }));
                        /**
                         * if a rider sent invites,
                         * show him a notification-modal on the confirmation page
                         */
                        sessionStorage.setItem(ONBOARDING_INVITES_SENT, ONBOARDING_INVITES_SENT);
                    }
                    sessionStorage.removeItem(ONBOARDING_INTERRUPTED);

                    onCompleteCollectUserInfoWizardTab({ isLastStep: true });
                }}
            />
        );

        switch (currentStep) {
            case COLLECT_USER_INFO_RIDER_STEP_1:
                return ageForm;
            case COLLECT_USER_INFO_RIDER_STEP_2:
                return locationForm;
            case COLLECT_USER_INFO_RIDER_STEP_3:
                return interestForm;
            case COLLECT_USER_INFO_RIDER_STEP_4:
                return availabilityForm;
            case COLLECT_USER_INFO_RIDER_STEP_5:
                return disciplineForm;
            case COLLECT_USER_INFO_RIDER_STEP_6:
                return levelForm;
            case COLLECT_USER_INFO_RIDER_STEP_7:
                return aboutForm;
            case COLLECT_USER_INFO_RIDER_STEP_8:
                return recommendationsForm;
            case COLLECT_USER_INFO_HORSEOWNER_STEP_1:
                return ageForm;
            case COLLECT_USER_INFO_HORSEOWNER_STEP_2:
                return locationForm;
            case COLLECT_USER_INFO_HORSEOWNER_STEP_3:
                return aboutForm;
            default:
                return null;
        }
    };

    const curentStepIndex = sectionTabs.indexOf(currentStep) + 1;
    const tabsStatus = tabsActive(profile);

    // If selectedTab is not active, redirect to the beginning of wizard
    if (!tabsStatus[currentStep] && !preventRedirect) {
        const currentTabIndex = supportedTabs.indexOf(currentStep);
        const nearestActiveTab = supportedTabs
            .slice(0, currentTabIndex)
            .reverse()
            .find(t => tabsStatus[t]);

        return <Redirect to={{ pathname: nearestActiveTab }} />;
    }

    const errorMessage =
        updateInfoError || (uploadDocumentsError && !userDocErrorMetadata) || promptUserBioError ? (
            <div className={css.error}>
                <div className={css.genericErrorContent}>
                    <p className={css.genericErrorText}>
                        {uploadDocumentsError || promptUserBioError || (
                            <FormattedMessage id="CollectUserInfoWizard.updateFailed" />
                        )}
                    </p>
                    <span onClick={() => onDiscardErrors()}>
                        <IconCloseCircleL />
                    </span>
                </div>
            </div>
        ) : null;

    return (
        <Page title={title} scrollingDisabled={!!interruptModalVisible}>
            <WizardNavigation
                progress={progressBarWidth}
                onInterrupt={interruptOnboarding}
                onNavigate={interruptOnboarding}
                interruptAllowed={interruptAllowed}
                navigationAllowed={interruptAllowed}
            />
            <LayoutWrapperMain blobBackground>
                <main
                    className={classNames(css.root, {
                        [css[`${currentStep}Step`]]: !!currentStep,
                    })}
                >
                    <div
                        className={classNames(css.collectInfoWrapper, {
                            [css.loading]: loading,
                        })}
                    >
                        <h5 className={css.collectInfoStep}>
                            <FormattedMessage
                                id={`CollectUserInfoWizard.${
                                    isRiderProfile || isHorseownerProfile
                                        ? 'yourProfileInfo'
                                        : 'yourAccountInfo'
                                }`}
                            />
                            <FormattedMessage
                                id="CollectUserInfoWizard.currentStepTitle"
                                values={{ current: curentStepIndex, total: sectionTabs.length }}
                            />
                        </h5>
                        {currentUser ? getCurrentStepForm() : <div>No user data found.</div>}
                    </div>
                    {errorMessage}
                </main>
            </LayoutWrapperMain>
            {interruptModalVisible && (
                <InterruptProcessModal
                    isOpen={interruptModalVisible}
                    onClose={() => setInterruptModalVisibility(false)}
                    handleInterruption={() => {
                        const interrupt = true;

                        sessionStorage.setItem(ONBOARDING_INTERRUPTED, ONBOARDING_INTERRUPTED);

                        onCompleteCollectUserInfoWizardTab({ interrupt });
                    }}
                >
                    <InterruptProcessModal.Icon />
                    <InterruptProcessModal.Heading />
                    <InterruptProcessModal.Description>
                        Bist Du sicher, dass Du abbrechen und ohne vollständiges Profil weiterfahren
                        möchtest?
                    </InterruptProcessModal.Description>
                    <InterruptProcessModal.Footer>
                        <InterruptProcessModal.Sidenote>
                            Deine bisherigen Eingaben werden gespeichert und Du kannst dein Profil
                            jederzeit vervollständigen.
                        </InterruptProcessModal.Sidenote>
                        <InterruptProcessModal.Button />
                    </InterruptProcessModal.Footer>
                </InterruptProcessModal>
            )}
        </Page>
    );
};

const mapStateToProps = state => {
    const { updateUserProfileInfoInProgress, updateUserProfileInfoError, currentUser } = state.user;
    const { uploadImageError, uploadInProgress, image } = state.ProfileSettingsPage;
    const { promptUserBioInProgress, promptUserBioError, promptUserBio } = state.OnboardingPage;

    return {
        updateInfoInProgress: updateUserProfileInfoInProgress,
        updateInfoError: updateUserProfileInfoError,
        uploadImageError,
        uploadInProgress,
        currentUser,
        profileImage: image,
        promptUserBioInProgress,
        promptUserBioError,
        promptUserBio,
    };
};

const mapDispatchToProps = dispatch => ({
    onImageUpload: data => dispatch(uploadImage(data)),
    onUpdateProfile: (data, publicData) => dispatch(updateProfile(data, publicData)),
    onUpdateUserInfo: (publicData, profileData, lastStep) =>
        dispatch(resolveCollectingUserInfo(publicData, profileData, lastStep)),
    onDiscardErrors: () => dispatch(discardErrors()),
    onPromptUserBio: () => dispatch(promptUserBio()),
});

const OnboardingPage = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(OnboardingPageComponent);

export default OnboardingPage;

import React, { useRef, createContext, useContext } from 'react';
import { Observer } from './observer';
import { getOneSignalSubscriptionsInfo, updateOneSignalAliasLabelById } from '../util/api';
import { ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT } from '../util/localStorage';
import { isLocalhost } from '../util/urlHelpers';

export const EVENT_OS_NOTIFICATIONS_INIT_SUCCESS = 'os_notifications_init_success';
export const EVENT_OS_NOTIFICATIONS_INIT_FAILED = 'os_notifications_init_failed';
export const EVENT_OS_NOTIFICATIONS_LOGIN_SUCCESS = 'os_notifications_login_success';
export const EVENT_OS_NOTIFICATIONS_LOGIN_FAILED = 'os_notifications_login_failed';
export const EVENT_OS_NOTIFICATIONS_LOGOUT_SUCCESS = 'os_notifications_logout_success';

export const ALIAS_ID_ONE_SIGNAL = 'onesignal_id';
export const ALIAS_ID_SUBSCRIPTION_ID = 'subscription_id';

const APP_ID = process.env.REACT_APP_ONESIGNAL_APP_ID;

export class OneSignalService extends Observer {
    constructor() {
        super();
    }
    /**
     * Check if a current user is already subscribed
     *
     * @returns data object with subscription being enabled/disabled
     */
    isSubscriptionsEnabled = async () => {
        try {
            const response = await getOneSignalSubscriptionsInfo();
            const data = await response.json();
            const { error, message, reason, data: subscriptions } = data;

            const checkIfEnabled = subscriptions =>
                Array.isArray(subscriptions) &&
                subscriptions.length > 0 &&
                subscriptions.every(s => s.enabled);

            const isNotFound = reason === 'not-found';

            if (error) {
                return {
                    isEnabled: false,
                    message,
                    error,
                    reason,
                    isNotFound,
                };
            }

            return { isEnabled: checkIfEnabled(subscriptions) };
        } catch (e) {
            return { isEnabled: false, message: e.message, error: true };
        }
    };

    checkPerSessionPrompt = () => {
        /** show to a user the prompt per session */
        return Boolean(window.sessionStorage.getItem(ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT));
    };

    getExternalId = currentUser =>
        `${currentUser.attributes.profile.publicData?.userType}-${currentUser.id?.uuid}`;

    addTag = (key, value) => {
        window.OneSignalDeferred.push(function() {
            window.OneSignal.User.addTag(key, value);
        });
    };

    init = () => {
        const self = this;

        if (!window.OneSignalDeferred) {
            this.publish(
                EVENT_OS_NOTIFICATIONS_INIT_FAILED,
                'OneSignalDeferred package was not found.'
            );
        }

        window.OneSignalDeferred.push(async function(OneSignal) {
            try {
                await OneSignal.init({ appId: APP_ID });

                self.publish(EVENT_OS_NOTIFICATIONS_INIT_SUCCESS);
            } catch (e) {
                self.publish(EVENT_OS_NOTIFICATIONS_INIT_FAILED, e.message);
            }
        });
    };

    logout = () => {
        const self = this;

        window.OneSignalDeferred.push(function() {
            window.OneSignal.logout();

            self.publish(EVENT_OS_NOTIFICATIONS_LOGOUT_SUCCESS);
        });
    };

    /**
     * A function that initiates OneSignal login with user data - user id & user type
     * @param {object | string} externalIdEntry - external id as a plain string or sdk user
     * @returns
     */
    login = externalIdEntry => {
        const self = this;

        const external_id =
            typeof externalIdEntry === 'string'
                ? externalIdEntry
                : this.extractExternalIdFromUserData(externalIdEntry);

        if (!external_id) {
            if (isLocalhost()) {
                console.warn(`Invalid external param provided: ${external_id}`);
            }

            this.publish(EVENT_OS_NOTIFICATIONS_LOGIN_FAILED);
        }

        window.OneSignalDeferred.push(async function(OneSignal) {
            await OneSignal.login(external_id);
            self.publish(EVENT_OS_NOTIFICATIONS_LOGIN_SUCCESS);
        });
    };

    // loginViaSubscriptionId = async subscriptions => {
    //     try {
    //         /** login a user to OS system */
    //         const subscription = subscriptions.find(s => s.id);
    //         const { error, message } = await this.updateOsAlias(
    //             subscription?.id,
    //             ALIAS_ID_SUBSCRIPTION_ID
    //         );

    //         if (!error) {
    //             return this.publish(EVENT_OS_NOTIFICATIONS_LOGIN_SUCCESS);
    //         }
    //         throw new Error(message);
    //     } catch (e) {
    //         return { error: true, message: e.message };
    //     }
    // };

    // loginViaOnesignalId = async onesignalId => {
    //     try {
    //         /** login a user to OS system */
    //         const { error, message } = await this.updateOsAlias(onesignalId, ALIAS_ID_ONE_SIGNAL);

    //         if (!error) {
    //             return this.publish(EVENT_OS_NOTIFICATIONS_LOGIN_SUCCESS);
    //         }
    //         throw new Error(message);
    //     } catch (e) {
    //         return { error: true, message: e.message };
    //     }
    // };
    /**
     * Update One signal alias by id & label pair;
     * possible combinations:
     * id - onesignal_id (actual id); label - 'onesignal_id';
     * id - subscription_id (actual id); label - 'subscription_id';
     *
     * @param {string} aliasId - id received from OS api
     * @param {string} aliasLabel - alias label, e.g. 'onesignal_id', 'subscription_id'
     */
    updateOsAlias = async (aliasId, aliasLabel) => {
        try {
            const response = await updateOneSignalAliasLabelById(aliasId, aliasLabel);
            const data = response.json();
            return data;
        } catch (e) {
            return { error: true, message: e.message };
        }
    };

    extractExternalIdFromUserData = sdkUser => {
        try {
            const {
                id: { uuid },
                attributes: {
                    profile: {
                        publicData: { userType },
                    },
                },
            } = sdkUser;
            return `${userType}-${uuid}`;
        } catch (e) {
            return null;
        }
    };
}

export const OneSignalServiceContext = createContext(null);

export const useOneSignal = () => useContext(OneSignalServiceContext);

export const oneSignalInstance = new OneSignalService();

export const OneSignaServiceProvider = ({ children }) => {
    const service = useRef(oneSignalInstance).current;

    return (
        <OneSignalServiceContext.Provider value={service}>
            {children}
        </OneSignalServiceContext.Provider>
    );
};

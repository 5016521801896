import React, { Component } from 'react';
import PropTypes, { array, bool } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ResponsiveImage, IconSpinner, SdkVideo } from '../../components';
import { propTypes } from '../../util/types';

import css from './ImageCarousel.css';
import { ArrowNextIcon } from '../../icons';

const KEY_CODE_LEFT_ARROW = 37;
const KEY_CODE_RIGHT_ARROW = 39;

const Video = ({ markImageLoaded, selectedImageIndex, image, renderVideoElem }) => {
    const description = image.description && <p className={css.description}>{image.description}</p>;

    const props = {
        image,
        markImageLoaded,
        selectedImageIndex,
        description,
        renderDescription: () => description,
        onLoadedData: markImageLoaded(selectedImageIndex),
    };

    if (typeof renderVideoElem === 'function') {
        return renderVideoElem(props);
    }

    return (
        <>
            <SdkVideo
                key={`${selectedImageIndex}-${image?.id?.uuid}`}
                onLoadedData={markImageLoaded(selectedImageIndex)}
                entity={image}
            />
            {description}
        </>
    );
};

const Image = ({
    markImageLoaded,
    selectedImageIndex,
    image,
    imageAltText,
    imageClasses,
    variants,
}) => {
    return (
        <>
            <ResponsiveImage
                className={imageClasses}
                alt={imageAltText}
                image={image}
                onLoad={markImageLoaded(selectedImageIndex)}
                onError={markImageLoaded(selectedImageIndex)}
                variants={[
                    'scaled-small',
                    'scaled-medium',
                    'scaled-large',
                    'scaled-xlarge',
                    ...variants,
                ]}
                sizes="(max-width: 767px) 100vw, 80vw"
            />
            {image.description && <p className={css.description}>{image.description}</p>}
        </>
    );
};

class ImageCarousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedImageIndex: this.props.selectedImageIndex,
            selectedImageLoaded: false,
        };
        this.onKeyUp = this.onKeyUp.bind(this);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
    }
    componentDidMount() {
        window.addEventListener('keyup', this.onKeyUp);
    }
    componentWillUnmount() {
        window.removeEventListener('keyup', this.onKeyUp);
    }
    onKeyUp(e) {
        if (e.keyCode === KEY_CODE_LEFT_ARROW) {
            this.prev();
        } else if (e.keyCode === KEY_CODE_RIGHT_ARROW) {
            this.next();
        }
    }
    prev() {
        const count = this.props.images.length;
        if (count < 2) {
            return;
        }
        this.setState(prevState => {
            const newIndex = count > 0 ? (count + prevState.selectedImageIndex - 1) % count : 0;
            return { selectedImageIndex: newIndex, selectedImageLoaded: false };
        });
    }
    next() {
        const count = this.props.images.length;
        if (count < 2) {
            return;
        }
        this.setState(prevState => {
            const newIndex = count > 0 ? (count + prevState.selectedImageIndex + 1) % count : 0;
            return { selectedImageIndex: newIndex, selectedImageLoaded: false };
        });
    }
    render() {
        const {
            rootClassName,
            className,
            images,
            intl,
            variants = [],
            showIndex = true,
            renderVideoElem = null,
            renderEmbeddedElem = () => null,
        } = this.props;
        const { selectedImageIndex, selectedImageLoaded } = this.state;
        const classes = classNames(rootClassName || css.root, className);

        const naturalIndex = selectedImageIndex + 1;
        const imageIndex =
            showIndex && images.length > 0 ? (
                <span className={css.imageIndex}>
                    {naturalIndex} / {images.length}
                </span>
            ) : null;
        const prevButton =
            images.length > 1 ? (
                <button className={css.prev} onClick={this.prev}>
                    <ArrowNextIcon />
                </button>
            ) : null;
        const nextButton =
            images.length > 1 ? (
                <button className={css.next} onClick={this.next}>
                    <ArrowNextIcon />
                </button>
            ) : null;

        const imageAltText = intl.formatMessage(
            {
                id: 'ImageCarousel.imageAltText',
            },
            {
                index: naturalIndex,
                count: images.length,
            }
        );

        const markImageLoaded = index => () => {
            this.setState(prevState => {
                if (prevState.selectedImageIndex === index) {
                    // Only mark the image loaded if the current index hasn't
                    // changed, i.e. user hasn't already changed to another
                    // image index.
                    return { selectedImageLoaded: true };
                }
                return {};
            });
        };
        const image = images[selectedImageIndex] || {};

        const isVideo = image.type === 'video';
        const isEmbedded = image.type === 'embedded';
        const isImage = !isVideo && !isEmbedded;

        const currentImageIsLoaded = images.length === 0 || selectedImageLoaded;

        const loadingIconClasses = classNames(css.loading, {
            [css.loadingVisible]: !currentImageIsLoaded && !isEmbedded,
        });
        const imageClasses = classNames(css.image, {
            [css.imageLoading]: !currentImageIsLoaded,
        });

        const assetsCommonProps = {
            markImageLoaded,
            selectedImageIndex,
            image,
        };

        return (
            <div className={classes}>
                <div className={css.imageWrapper}>
                    <IconSpinner className={loadingIconClasses} backColor="#000000" />
                    {isEmbedded && renderEmbeddedElem(image)}
                    {isVideo && (
                        <Video
                            {...assetsCommonProps}
                            key={`${selectedImageIndex}-${image?.id?.uuid}`}
                            renderVideoElem={renderVideoElem}
                        />
                    )}
                    {isImage && (
                        <Image
                            imageClasses={imageClasses}
                            imageAltText={imageAltText}
                            variants={variants}
                            {...assetsCommonProps}
                        />
                    )}
                </div>
                {imageIndex}
                {prevButton}
                {nextButton}
            </div>
        );
    }
}

const { string, arrayOf, number, oneOfType } = PropTypes;

ImageCarousel.propTypes = {
    rootClassName: string,
    className: string,
    selectedImageIndex: number,
    images: oneOfType([arrayOf(propTypes.image), arrayOf(string)]).isRequired,
    variants: array,
    // from injectIntl
    intl: intlShape.isRequired,
    showIndex: bool,
};

export default injectIntl(ImageCarousel);

import React from 'react';
/** icon-boost-3x-l */
/** icon-boost-3x */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
        <path
            d="M10.8 3.938a2.093 2.093 0 0 0-.669.308c-.301.207-5.978 5.259-6.11 5.438-.102.138-.12.197-.12.411-.001.207.018.279.109.417a.738.738 0 0 0 1.079.166c.094-.075 1.459-1.281 3.034-2.681 1.747-1.553 2.914-2.562 2.996-2.591a.77.77 0 0 1 .534.008c.071.03 1.461 1.239 3.088 2.686 1.628 1.447 3.017 2.656 3.088 2.686a.758.758 0 0 0 1.031-.55.807.807 0 0 0-.149-.578c-.143-.182-5.911-5.297-6.138-5.443a2.104 2.104 0 0 0-1.2-.331 2.936 2.936 0 0 0-.573.054m.08 4.482a2.32 2.32 0 0 0-.92.448c-.098.083-1.477 1.307-3.064 2.718-3.06 2.722-3.017 2.68-3.015 3.025.002.301.254.625.55.707.413.115.274.22 3.537-2.683 1.632-1.453 3.022-2.669 3.088-2.703.146-.076.499-.078.637-.005.055.029 1.413 1.22 3.018 2.646C16.316 14 17.68 15.2 17.742 15.241c.623.409 1.4-.36 1.023-1.013-.084-.146-5.851-5.275-6.178-5.494a2.273 2.273 0 0 0-1.707-.314m-.08 4.518a2.093 2.093 0 0 0-.669.308c-.301.207-5.978 5.259-6.11 5.438-.102.138-.12.197-.12.411-.001.207.018.279.109.417a.738.738 0 0 0 1.079.166c.094-.075 1.459-1.281 3.034-2.681 1.747-1.553 2.914-2.562 2.996-2.591a.77.77 0 0 1 .534.008c.071.03 1.461 1.239 3.088 2.686 1.628 1.447 3.017 2.656 3.088 2.686a.816.816 0 0 0 .631-.026.73.73 0 0 0 .251-1.102c-.143-.182-5.911-5.297-6.138-5.443a2.104 2.104 0 0 0-1.2-.331 2.936 2.936 0 0 0-.573.054"
            fillRule="evenodd"
            fill="#000"
        />
    </svg>
);

import React from 'react';
import { useListings } from '../../../hooks/useListings';
import { IconSpinner, ListingCard } from '../../../components';
import config from '../../../config';
import css from './ListingClosedOverlay.css';

const { listingTypeHorse } = config;

export default ({ currentListing }) => {
    const { lat, lng } = currentListing.attributes.geolocation;
    const { location, postalCode } = currentListing?.attributes?.publicData?.addressDetails || {};

    const [listings, inProgress] = useListings({
        allowed: lat && lng && location && postalCode,
        addDataToMarketplace: false,
        params: {
            per_page: 6,
            include: [],
            'fields.listing': ['title', 'state', 'geolocation', 'price', 'publicData', 'createdAt'],
            'fields.image': [
                'variants.landscape-crop',
                'variants.landscape-crop2x',
                'variants.square-small',
            ],
            pub_type: listingTypeHorse,
            origin: `${lat},${lng}`, // sort by distance by default
        },
    });

    const dataAvailable = !inProgress && listings && listings.length > 0 && location && postalCode;

    return (
        <div className={css.root}>
            {inProgress && <IconSpinner />}

            {dataAvailable && (
                <div className={css.container}>
                    <h2 className={css.heading}>
                        Weitere Pferde in der Nähe von {postalCode} {location}
                    </h2>
                    <div className={css.cards}>
                        {listings.map(l => (
                            <li className={css.listing} key={l.id.uuid}>
                                <ListingCard listing={l} />
                            </li>
                        ))}
                    </div>
                    <div className={css.footerOverlay} />
                </div>
            )}
        </div>
    );
};

import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { uploadUserAvatarToGallery } from '../../ducks/Assets.duck';

// ================ Action types ================ //

export const CLEAR_UPDATED_FORM = 'app/ProfileSettingsPage/CLEAR_UPDATED_FORM';

export const UPLOAD_IMAGE_REQUEST = 'app/ProfileSettingsPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/ProfileSettingsPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/ProfileSettingsPage/UPLOAD_IMAGE_ERROR';

export const UPLOAD_GALLERY_IMAGE_REQUEST = 'app/ProfileSettingsPage/UPLOAD_GALLERY_IMAGE_REQUEST';
export const UPLOAD_GALLERY_IMAGE_SUCCESS = 'app/ProfileSettingsPage/UPLOAD_GALLERY_IMAGE_SUCCESS';
export const UPLOAD_GALLERY_IMAGE_FAILURE = 'app/ProfileSettingsPage/UPLOAD_GALLERY_IMAGE_FAILURE';

export const UPDATE_PROFILE_REQUEST = 'app/ProfileSettingsPage/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/ProfileSettingsPage/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/ProfileSettingsPage/UPDATE_PROFILE_ERROR';

// ================ Reducer ================ //

const initialState = {
    image: null,
    uploadImageError: null,
    uploadInProgress: false,
    updateInProgress: false,
    updateProfileError: null,
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case UPLOAD_IMAGE_REQUEST:
            return {
                ...state,
                image: { ...payload.params },
                uploadInProgress: true,
                uploadImageError: null,
            };
        case UPLOAD_IMAGE_SUCCESS: {
            // payload: { id: 'tempId', uploadedImage }
            const { id, uploadedImage } = payload;
            const { file } = state.image || {};
            const image = { id, imageId: uploadedImage.id, file, uploadedImage };
            return { ...state, image, uploadInProgress: false };
        }
        case UPLOAD_IMAGE_ERROR: {
            // eslint-disable-next-line no-console
            return {
                ...state,
                /**
                 * image: null
                 * removes profileImage OF data after a user initially uploads an avatar
                 */
                // image: null,
                uploadInProgress: false,
                uploadImageError: payload.error,
            };
        }

        case UPLOAD_GALLERY_IMAGE_REQUEST:
            return {
                ...state,
                updateInProgress: true,
                updateProfileError: null,
            };
        case UPLOAD_GALLERY_IMAGE_SUCCESS:
            return {
                ...state,
                updateInProgress: false,
                updateProfileError: null,
            };
        case UPLOAD_GALLERY_IMAGE_FAILURE:
            return {
                ...state,
                updateInProgress: true,
                updateProfileError: payload,
            };

        case UPDATE_PROFILE_REQUEST:
            return {
                ...state,
                updateInProgress: true,
                updateProfileError: null,
            };
        case UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                /**
                 * image: null
                 * removes profileImage OF data after a user initially uploads an avatar
                 */
                // image: null,
                updateInProgress: false,
            };
        case UPDATE_PROFILE_ERROR:
            return {
                ...state,
                image: null,
                updateInProgress: false,
                updateProfileError: payload,
            };

        case CLEAR_UPDATED_FORM:
            return { ...state, updateProfileError: null, uploadImageError: null };

        default:
            return state;
    }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const clearUpdatedForm = () => ({
    type: CLEAR_UPDATED_FORM,
});

// SDK method: images.upload
export const uploadImageRequest = params => ({ type: UPLOAD_IMAGE_REQUEST, payload: { params } });
export const uploadImageSuccess = result => ({ type: UPLOAD_IMAGE_SUCCESS, payload: result.data });
export const uploadImageError = error => ({
    type: UPLOAD_IMAGE_ERROR,
    payload: error,
    error: true,
});

export const uploadGalleryImageRequest = () => ({ type: UPLOAD_GALLERY_IMAGE_REQUEST });
export const uploadGalleryImageSuccess = () => ({ type: UPLOAD_GALLERY_IMAGE_SUCCESS });
export const uploadGalleryImageFailure = error => ({
    type: UPLOAD_GALLERY_IMAGE_FAILURE,
    payload: error,
});

// SDK method: sdk.currentUser.updateProfile
export const updateProfileRequest = params => ({
    type: UPDATE_PROFILE_REQUEST,
    payload: { params },
});
export const updateProfileSuccess = result => ({
    type: UPDATE_PROFILE_SUCCESS,
    payload: result.data,
});
export const updateProfileError = error => ({
    type: UPDATE_PROFILE_ERROR,
    payload: error,
    error: true,
});

// ================ Thunk ================ //

// Images return imageId which we need to map with previously generated temporary id
export function uploadImage({ id, file }) {
    return (dispatch, getState, sdk) => {
        dispatch(uploadImageRequest({ id, file }));

        const bodyParams = { image: file };

        const queryParams = {
            expand: true,
            'fields.image': ['variants.square-small', 'variants.square-small2x'],
        };
        return sdk.images
            .upload(bodyParams, queryParams)
            .then(resp => {
                const uploadedImage = resp.data.data;
                dispatch(uploadImageSuccess({ data: { id, uploadedImage } }));
                dispatch(uploadUserAvatarToGallery(file));
            })
            .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
    };
}
// TODO use user.duck.js thunk instead
export const updateProfile = (actionPayload = {}, publicData = {}) => (dispatch, _, sdk) => {
    dispatch(updateProfileRequest());

    const queryParams = {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.currentUser
        .updateProfile(
            {
                ...actionPayload,
                publicData: {
                    ...publicData,
                },
            },
            queryParams
        )
        .then(response => {
            dispatch(updateProfileSuccess(response));

            const entities = denormalisedResponseEntities(response);
            if (entities.length !== 1) {
                throw new Error(
                    'Expected a resource in the sdk.currentUser.updateProfile response'
                );
            }
            const currentUser = entities[0];

            dispatch(
                addMarketplaceEntities({
                    data: {
                        data: {
                            ...currentUser,
                            type: 'currentUser',
                        },
                    },
                })
            );

            // Update current user in state.user.currentUser through user.duck.js
            dispatch(currentUserShowSuccess(currentUser));
        })
        .catch(e => dispatch(updateProfileError(storableError(e))));
};
